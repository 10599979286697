// Define a list of abusive words to check
export const abusiveWords = [
    "idiot",
    "stupid",
    "dumb",
    "fool",
    "loser",
    "trash",
    "garbage",
    "ugly",
    "hate",
    "bastard",
    "scum",
    "moron",
    "shut up",
    "suck",
    "psycho",
    "jerk",
    "lame",
    "pathetic",
    "freak",
    "weirdo",
    "ass",
    "damn",
    "hell",
    "crap",
    "screw",
    "bloody",
    "prick",
    "slut",
    "whore",
    "bitch",
    "dick",
    "piss",
    "shit",
    "arse",
    "bugger",
    "wanker",
    "bollocks",
    "tosser",
    "cunt",
    "jackass",
    "douche",
    "twat",
    "nazi",
    "racist",
    "choke",
    "die",
    "kill",
    "stink",
    "pig",
    "scumbag",
    "fat",
    "lazy",
    "cow",
    "dog",
    "filth",
    "imbecile",
    "nerd",
    "geek",
    "loser",
    "nonsense",
    "bimbo",
    "idiocy",
    "cretin",
    "clown",
    "worthless",
    "waste",
    "dope",
    "tool",
    "ape",
    "donkey",
    "thick",
    "snob",
    "villain",
    "parasite",
    "worm",
    "rat",
    "leech",
    "brat",
    "barbarian",
    "peasant",
    "psycho",
    "maniac",
  ];