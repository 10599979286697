import React, { ChangeEvent } from "react";

type InputFieldProps = {
  label?: string; // Label for the input field
  type?: "text" | "password" | "email" | "number" | "search"; // Input type
  placeholder?: string; // Placeholder text
  value?: string; // Value of the input field
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void; // Change handler
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void; // Change handler
  error?: boolean; // Error message
  errorMsg?: string; // Error message
  disabled?: boolean; // Disable the input
  className?: string; // Custom classes for styling
  id?: string;
  name?: string;
};

const InputField: React.FC<InputFieldProps> = ({
  label,
  name,
  type = "text",
  placeholder = "",
  value = "",
  onChange,
  error,
  errorMsg,
  disabled = false,
  className = "",
  id = "",
  onBlur,
}) => {
  return (
    <div className={`w-full ${className}`}>
      {label && (
        <label className="block mb-2 text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <input
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        className={`w-full px-4 py-2 border rounded-md text-[#002051] placeholder:text-[#002051] bg-white disabled:opacity-50 disabled:cursor-not-allowed focus-visible:outline-0 ${
          error ? "border-red-500" : "border-gray-300"
        }`}
        aria-invalid={!!error}
        aria-label={placeholder || label}
      />
      {error && <p className="mt-1 text-sm text-red-500">{errorMsg}</p>}
    </div>
  );
};

export default InputField;
