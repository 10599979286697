import React from "react";
import { useFormik } from "formik";
import formValidationSchema from "../utils/validationSchema";
import InputField from "./ui/inputField";
import Button from "./ui/button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { handleGTM } from "../utils/helper";

interface FormProps {
  screenId: string;
}

const Form: React.FC<FormProps> = ({ screenId }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      receiverEmail: "",
      familyName: "",
      senderEmail: "",
    },
    validationSchema: formValidationSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        if (
          values.receiverEmail.trim().toLowerCase() ===
          values.senderEmail.trim().toLowerCase()
        ) {
          return alert("Sender email and receiver email cannot be the same");
        }
        handleGTM("ENGAGE_MALL_TAP_SEND_WISHES");
        const payload = {
          receiverEmail: values.receiverEmail,
          familyName: values.familyName,
          senderEmail: values.senderEmail,
          screenId: screenId,
        };
        await axios.post(
          `${process.env.REACT_APP_HTTP_BACKEND_URL}api/messages`,
          payload
        );

        navigate("/thank-you?mall");
      } catch (error: any) {
        console.error("Error submitting form:", error);
        if (error?.status === 400) {
          setFieldError(
            "familyName",
            "Family name contains inappropriate language"
          );
        } else {
          alert("Something went wrong. Please try again later.");
        }
      }
    },
  });

  return (
    <div className="form-orientation w-full h-full flex flex-col items-center justify-end gap-3">
      <h2 className="text-xl text-white font-avenirnext font-medium">
        Enter the details below
      </h2>
      <form
        className="flex flex-col gap-2 w-[70%]"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <InputField
          placeholder="Email id of Receiver"
          id="receiverEmail"
          name="receiverEmail"
          type="email"
          value={formik.values.receiverEmail}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(
            formik.touched.receiverEmail && formik.errors.receiverEmail
          )}
          errorMsg={formik.errors.receiverEmail}
        />
        <InputField
          placeholder="Your Family Name"
          id="familyName"
          name="familyName"
          type="text"
          value={formik.values.familyName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.familyName && formik.errors.familyName)}
          errorMsg={formik.errors.familyName}
        />
        <InputField
          placeholder="Your Email Id"
          id="senderEmail"
          name="senderEmail"
          type="email"
          value={formik.values.senderEmail}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(
            formik.touched.senderEmail && formik.errors.senderEmail
          )}
          errorMsg={formik.errors.senderEmail}
        />
        <Button
          text="SEND WISHES"
          type="submit"
          loading={formik.isSubmitting}
          disabled={formik.isSubmitting}
        />
      </form>
    </div>
  );
};

export default Form;
