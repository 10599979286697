import React, { useState } from "react";
import { useFormik } from "formik";
import InputField from "./ui/inputField";
import Button from "./ui/button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import formValidationSchema from "../utils/validationSchema";
import { handleGTM } from "../utils/helper";

const PhoneForm: React.FC = () => {
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      receiverEmail: "",
      familyName: "",
      senderEmail: "",
    },
    validationSchema: formValidationSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        if (
          values.receiverEmail.trim().toLowerCase() ===
          values.senderEmail.trim().toLowerCase()
        ) {
          return alert("Sender email and receiver email cannot be the same");
        }
        handleGTM("ENGAGE_MICRO_TAP_SEND_WISHES");
        const payload = {
          receiverEmail: values.receiverEmail,
          familyName: values.familyName,
          senderEmail: values.senderEmail,
        };
        // Submit data to the API
        await axios.post(
          `${process.env.REACT_APP_HTTP_BACKEND_URL}api/messages`,
          payload
        );

        // Redirect to the thank-you page
        navigate("/thank-you?ads");
      } catch (error: any) {
        // Handle API errors
        console.error("Error submitting form:", error);
        if (error?.status === 400) {
          setFieldError(
            "familyName",
            "Family name contains inappropriate language"
          );
        } else {
          alert("Something went wrong. Please try again later.");
        }
      }
    },
  });

  const handlePageClick = () => {
    if (showForm) return;
    setShowForm(true);
    handleGTM("ENGAGE_TAP_START_NOW");
  };

  return (
    <div className="form-orientation w-full h-full flex flex-col items-center justify-end gap-3">
      {!showForm ? (
        <div className="form-orientation flex flex-col gap-8 items-center w-full h-full justify-end">
          <div
            className="fixed w-screen h-[100dvh] top-0 bottom-0 left-0 right-0 z-10 !bg-transparent"
            onClick={handlePageClick}
            role="presentation"
          />
          <img
            src="image/makeCustomChristmas.png"
            alt="lady's choice meyo"
            className="w-[90%]"
            width={523}
            height={284}
          />
          <Button
            text="START NOW"
            type="button"
            className="justify-end !px-12"
            onClick={() => {
              setShowForm(true);
              handleGTM("ENGAGE_TAP_START_NOW");
            }}
          />
        </div>
      ) : (
        <>
          <h2 className="text-xl text-white font-avenirnext font-medium mt-12">
            Enter the details below
          </h2>
          <form
            className="flex flex-col gap-2 w-[70%]"
            noValidate
            onSubmit={formik.handleSubmit}
          >
            <InputField
              placeholder="Email id of Receiver"
              id="receiverEmail"
              name="receiverEmail"
              type="email"
              value={formik.values.receiverEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(
                formik.touched.receiverEmail && formik.errors.receiverEmail
              )}
              errorMsg={formik.errors.receiverEmail}
            />
            <InputField
              placeholder="Your Family Name"
              id="familyName"
              name="familyName"
              type="text"
              value={formik.values.familyName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(
                formik.touched.familyName && formik.errors.familyName
              )}
              errorMsg={formik.errors.familyName}
            />
            <InputField
              placeholder="Your Email Id"
              id="senderEmail"
              name="senderEmail"
              type="email"
              value={formik.values.senderEmail}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={Boolean(
                formik.touched.senderEmail && formik.errors.senderEmail
              )}
              errorMsg={formik.errors.senderEmail}
            />
            <Button
              text="SEND WISHES"
              className="mt-2"
              type="submit"
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting}
            />
          </form>
        </>
      )}
    </div>
  );
};

export default PhoneForm;
