import React from "react";
import PhoneForm from "../components/phoneForm";

const PhonePage: React.FC = () => {
  return (
    <div className="relative mobile-form flex flex-col w-screen h-[100dvh] ads-support-form">
      <img
        src="logo.png"
        alt="lady's choice logo"
        className="absolute top-3 left-6 z-10 w-[92px]"
        width={136}
        height={173}
      />
      <div className="form-wrapper w-full h-1/2 bg-[#002051]">
        <PhoneForm />
      </div>
      <div className="video-wrapper relative top-shadow bg-image w-full h-1/2">
        <video
          src="/video/bookOpenFillDetails.mp4" // Ensure the correct path
          className="w-full h-full object-cover"
          controls={false}
          autoPlay
          muted
          loop
          playsInline
          preload="auto"
        >
          Your browser does not support the video tag.
        </video>
       
      </div>
    </div>
  );
};

export default PhonePage;
