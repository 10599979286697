import * as Yup from "yup";
import { abusiveWords } from "../constant";



const formValidationSchema = Yup.object({
  receiverEmail: Yup.string()
    .email("Invalid receiver email address")
    .required("Receiver email is required"),
  familyName: Yup.string()
    .min(2, "Family name must be at least 2 characters")
    .max(20, "Family name must be at most 20 characters")
    .required("Family name is required")
    .matches(
      /^[a-zA-Z\s]*$/, // Regular expression to disallow special characters (only letters and spaces)
      "Family name can only contain letters and spaces"
    )
    .test(
      "no-abusive-words",
      "Family name contains inappropriate language",
      (value) => {
        if (!value) return true; // Skip validation if value is undefined
        const lowerCaseValue = value.toLowerCase();
        return !abusiveWords.some((word) => lowerCaseValue.includes(word));
      }
    ),
  senderEmail: Yup.string()
    .email("Invalid email address")
    .required("Sender email is required"),
}).test(
  "emails-not-same",
  "Sender email and receiver email cannot be the same",
  function (value) {
    // Ensure parent exists and contains the necessary fields
    const { senderEmail, receiverEmail } = this.parent || {};
    if (!senderEmail || !receiverEmail) return true; // Skip validation if either email is undefined
    return (
      senderEmail.trim().toLowerCase() !== receiverEmail.trim().toLowerCase()
    ); // Ensure emails are not the same
  }
);

export default formValidationSchema;
