import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import QRPage from "./pages/qrPage";

import './App.css';
import ThankYou from "./pages/thankYou";
import PhonePage from "./pages/phoneForm";
import Banner from "./pages/banner";

const App: React.FC = () => {
  return (
    <div className="App">
      <Routes>
        <Route path="/form" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/" element={<QRPage />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/phone-form" element={<PhonePage />} />
        <Route path="/banner" element={< Banner/>} />
      </Routes>
    </div>
  );
};

export default App;

