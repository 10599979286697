import { useState, useEffect, useCallback } from "react";

function useResponsiveScale() {
  const [state, setState] = useState({
    pageRatio: 0.62, // Initial ratio
    scaleValue: 100, // Initial scale value
    topValue: 0,     // Initial top value
  });

  const customDebounce = useCallback((func, delay) => {
    let timeoutId;

    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }, []);

  useEffect(() => {
    const handleResize = customDebounce(() => {
      const referenceWidth = 2160;
      const referenceHeight = 3480;

      const currentWidth = window.innerWidth;
      const currentHeight = window.innerHeight;

      // Calculate scale based on height proportion to the reference
      const heightScale = (currentHeight / referenceHeight) * 100;
      const widthScale = (currentWidth / referenceWidth) * 100;

      // Use the smaller of the two scales to maintain aspect ratio
      const newScaleValue = Math.min(heightScale, widthScale);

      // Adjust top value to center content vertically
      const newTopValue = (100 - newScaleValue) / 2;

      setState((prev) => ({
        ...prev,
        scaleValue: newScaleValue,
        topValue: newTopValue,
        pageRatio: currentHeight / currentWidth,
      }));
    }, 200); // Debounce delay of 200ms

    handleResize(); // Initial calculation

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [customDebounce]);

  return state;
}

export default useResponsiveScale;
