import React, { useCallback, useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { connectSocketIO } from "../utils/socket";
import { handleGTM } from "../utils/helper";
import useResponsiveScale from "../utils/dynamicScaling";

const QRPage: React.FC = () => {
  const { scaleValue = 100, topValue = 0 } = useResponsiveScale();
  const [screenId, setScreenId] = useState<string | null>(null);
  const [familyNamesQueue, setFamilyNamesQueue] = useState<string[]>([]);
  const [currentFamilyName, setCurrentFamilyName] = useState<string | null>(
    null
  );
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const socketRef = useRef<any>(null);

  // Handle 'screenId' event
  const handleScreenIdEvent = useCallback((data: any) => {
    if (data?.screenId) {
      setScreenId(data.screenId);
    } else {
      console.error("No screenId in event data");
    }
  }, []);

  // Handle 'updateFamilyName' event - manage a queue of family names
  const handleFamilyNameEvent = useCallback((data: any) => {
    if (data?.familyName) {
      setFamilyNamesQueue((prevQueue) => {
        const newQueue = [...prevQueue, data.familyName];

        return newQueue;
      });
    } else {
      console.error("No familyName in event data");
    }
  }, []);

  useEffect(() => {
    // Establish WebSocket connection

    socketRef.current = connectSocketIO();
    const socket = socketRef.current;

    // Listen for events
    socket.on("screenId", handleScreenIdEvent);
    socket.on("updateFamilyName", handleFamilyNameEvent);

    // Cleanup on unmount
    return () => {
      socket.off("screenId", handleScreenIdEvent);
      socket.off("updateFamilyName", handleFamilyNameEvent);
      socket.disconnect();
    };
  }, [handleScreenIdEvent, handleFamilyNameEvent]);

  useEffect(() => {
    // Function to display family names one by one with delay
    const displayFamilyNames = async () => {
      if (familyNamesQueue.length > 0) {
        handleGTM("ENGAGE_LC_SCAN_QR");

        for (let i = 0; i < familyNamesQueue.length; i++) {
          setCurrentFamilyName(familyNamesQueue[i]);
          setShowVideo(true);
          // Wait for a few seconds before showing the next family name
          await new Promise((resolve) => setTimeout(resolve, 15000)); // 15 seconds delay
        }

        // Once all family names have been shown, clear the queue

        setFamilyNamesQueue([]);
        setShowVideo(false); // Hide the video when finished
      }
    };

    if (familyNamesQueue.length > 0) {
      displayFamilyNames();
    }
  }, [familyNamesQueue]);

  // Generate the QR code URL with or without the screenId
  const adsParams = "utm_source=Inmall&utm_medium=QR&utm_campaign=LC";
  const qrCodeValue = screenId
    ? `${window.location.origin}/form?screenId=${screenId}&${adsParams}`
    : `${window.location.origin}/form?${adsParams}`;

  const dynamicStyle = {
    transform: `translate(-50%, -${topValue}%) scale(${scaleValue / 100})`,
  };

  return (
    <div
      className="main-wrapper qr-page w-[2160px] bg-[#002051] h-[3480px]"
      style={dynamicStyle}
    >
      {!showVideo ? (
        <div className="relative bg-[#002051] h-[3480px] flex flex-col justify-between">
          <div className="h-1/2 relative z-50">
            {/* QR Code and Static Content */}
            <div className="flex justify-center w-full">
              <p className="text-white font-manrope text-center max-w-[1311px] w-full mt-[10rem] text-[82.52px] tracking-[0.01px]">
                SCAN QR TO MAKE A CUSTOM CHRISTMAS GREETING WITH
                <br />
                <span className="font-bold">JOSE MARI CHAN!</span>
              </p>
            </div>

            <div className="flex justify-center mt-[5rem]">
              <QRCode
                className="border-[12px] border-white size-[640px]"
                value={qrCodeValue}
              />
            </div>
            <div className="max-w-[1362px] w-full mx-auto relative">
              <div className="init-name-spark w-full absolute -top-[32px] h-[520px] overflow-hidden " />
              <div
                className="mt-[5rem] animate-grow overflow-hidden"
                style={{ maxWidth: "1362px" }}
              >
                <div className="w-[1362px] h-full overflow-hidden">
                  <img
                    src="image/SendSpecialChristmasWishes.png"
                    alt="special wishes"
                    width={1362}
                    height={358}
                    style={{ height: "358px", width: "1362px" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center relative h-[1720px]">
            <div className="w-[2160px] overflow-hidden">
              <div className="w-[4500px] h-[3500px]">
                <video
                  src="/video/JMCCharacterAnimation.mp4" // Ensure the correct path
                  className="w-full h-full object-cover -translate-x-[1250px] -translate-y-[376px]"
                  loop
                  autoPlay
                  muted
                  playsInline
                  preload="auto"
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <img
              src="image/meyoJar.png"
              alt="lady's choice meyo"
              className="absolute bottom-[400px] right-[400px] mr-20 z-10 w-[304px]"
              width={304}
              height={385}
            />
            <img
              src="logo.png"
              alt="lady's choice logo"
              className="absolute bottom-[100px] right-[100px] z-10 w-[809px] h-[368px]"
              width={809}
              height={368}
            />
          </div>
        </div>
      ) : (
        <div className="relative h-[3480px]">
          <div className="h-1/2">
            <img
              src="logo.png"
              alt="lady's choice logo"
              className="absolute top-[100px] left-[100px] z-10 w-[556px]"
              width={556}
              height={256}
            />
            <div className="relative flex justify-center h-full qr-bg-image">
              <video
                src="/video/greetingVideoDooh.mp4" // Ensure the correct path
                className="w-full object-cover"
                controls={false}
                autoPlay
                muted
                loop
                playsInline
                preload="auto"
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="h-1/2 bg-[#002051] p-3">
            <div className="relative -translate-y-1/2">
              <div className="ml-[100px] relative w-fit">
                <img
                  src="image/greetFrame.png"
                  className=""
                  alt="greet"
                  width={1335}
                  height={1565}
                />
                <div className="name-spark w-full absolute h-[364px] top-[820px] overflow-hidden" />
                <span className="max-w-[900px] w-full font-fondy text-white text-[160px] leading-[182px] text-center capitalize absolute left-1/2 -translate-x-1/2 top-[820px]">
                  {currentFamilyName}khan Family
                </span>
              </div>
            </div>
            <div className="flex flex-row-reverse items-end justify-between pt-[10px] absolute bottom-[300px] left-1/2 -translate-x-1/2 max-w-[1800px] w-full">
              <div className="flex flex-col">
                <p className="text-white font-avenirltpromedium text-center mt-4 text-[72px]">
                  SCAN QR TO
                </p>
                <div className="flex justify-center my-4">
                  <QRCode
                    className="border-[12px] border-white size-[376px]"
                    value={qrCodeValue}
                  />
                </div>
              </div>
              <div className="max-w-[1192px] w-full relative overflow-hidden">
                <div className="init-name-spark-2 w-full absolute top-3 h-[354px]" />
                <div
                  className="mt-[45px] animate-grow overflow-hidden"
                  style={{ maxWidth: "1192px" }}
                >
                  <div className="w-[1192px] h-full overflow-hidden">
                    <img
                      src="image/SendSpecialChristmasWishes.png"
                      alt="special wishes"
                      width={1192}
                      height={309}
                      style={{ height: "309px", width: "1192px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QRPage;
