import React from "react";

type ButtonProps = {
  text?: string; // The button's text
  onClick?: () => void; // Click handler function
  type?: "button" | "submit" | "reset"; // Button type
  disabled?: boolean; // Disable the button
  loading?: boolean; // Show a loading indicator
  className?: string; // Custom classes for styling
};

const Button: React.FC<ButtonProps> = ({
  text = "Button",
  onClick,
  type = "button",
  disabled = false,
  loading = false,
  className = "",
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={`px-4 py-[9px] rounded-3xl text-white bg-[#f31c28] disabled:opacity-50 disabled:cursor-not-allowed transition-all ${className}`}
      aria-disabled={disabled || loading}
    >
      {loading ? (
        <span className="flex items-center justify-center">
          <svg
            className="animate-spin h-4 w-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
            ></path>
          </svg>
          Loading...
        </span>
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
